import React from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import { useIntl } from "gatsby-plugin-react-intl";

import QrSection from '../../components/QrSection';
import QrSectionTitle from '../../components/QrSectionTitle';
import QrImage from '../../components/QrImage';
import QrIcon from '../../components/QrIcon';
import ImgAbout1 from '../../assets/images/img_about_1.svg';
import ImgAbout2 from '../../assets/images/img_about_2.svg';
import ImgAbout3 from '../../assets/images/img_about_3.svg';
import ImgAbout4 from '../../assets/images/img_about_4.svg';
import ImgAboutTeam from '../../assets/images/img_team_1.jpg';

const About = (props) => {
    const intl = useIntl();

    return (
        <div id="about">
            <QrSection id="qr-about" gutters={true}>
                <Container>
                    <QrSectionTitle
                        section={intl.formatMessage({ id: "ABOUT.SECTION" })}
                        title={intl.formatMessage({ id: "ABOUT.TITLE" })}
                        description={intl.formatMessage({ id: "ABOUT.DESCRIPTION" })}
                    />
                    <Row id="qr-about-how-to-work">
                        <Col sm={6} lg={3} className="mb-4">
                            <QrImage
                                src={ImgAbout1}
                                ariaLabel={intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.PLAY_TITLE" })}
                            />
                            <h4>{intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.PLAY_TITLE" })}</h4>
                            <p>{intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.PLAY_TEXT" })}</p>
                        </Col>
                        <Col sm={6} lg={3} className="mb-4">
                            <QrImage
                                src={ImgAbout2}
                                ariaLabel={intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.FUN_TITLE" })}
                            />
                            <h4>{intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.FUN_TITLE" })}</h4>
                            <p>{intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.FUN_TEXT" })}</p>
                        </Col>
                        <Col sm={6} lg={3} className="mb-4">
                            <QrImage
                                src={ImgAbout3}
                                ariaLabel={intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.EARN_QIS_TITLE" })}
                            />
                            <h4>{intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.EARN_QIS_TITLE" })}</h4>
                            <p>{intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.EARN_QIS_TEXT" })}</p>
                        </Col>
                        <Col sm={6} lg={3} className="mb-4">
                            <QrImage
                                src={ImgAbout4}
                                ariaLabel={intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.PRIZES_TITLE" })}
                            />
                            <h4>{intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.PRIZES_TITLE" })}</h4>
                            <p>{intl.formatMessage({ id: "ABOUT.HOW_TO_WORK.PRIZES_TEXT" })}</p>
                        </Col>
                    </Row>
                    <Row id="qr-about-team">
                        <Col lg={6}>
                            <Image src={ImgAboutTeam} alt={intl.formatMessage({ id: "ABOUT.TEAM.TITLE" })} fluid />
                        </Col>
                        <Col lg={6} className="mt-4 mt-lg-0">
                            <Row id="qr-about-team-text">
                                <Col xs={12}>
                                    <h4>{intl.formatMessage({ id: "ABOUT.TEAM.TITLE" })}</h4>
                                    <p>{intl.formatMessage({ id: "ABOUT.TEAM.TEXT" })}</p>
                                </Col>
                            </Row>
                            <Row id="qr-about-team-content">
                                <Col xs={6} className="d-flex align-items-center mb-4">
                                    <div className="me-3">
                                        <QrIcon ic="gift_outline" size={40} />
                                    </div>
                                    <div>
                                        <h6>{intl.formatMessage({ id: "ABOUT.TEAM.PRIZES_TITLE" })}</h6>
                                        <p>{`+ 27 ${intl.formatMessage({ id: "LABEL.THOUSAND" })}`}</p>
                                    </div>
                                </Col>
                                <Col xs={6} className="d-flex align-items-center mb-4">
                                    <div className="me-3">
                                        <QrIcon ic="questions_outline" size={40} />
                                    </div>
                                    <div>
                                        <h6>{intl.formatMessage({ id: "ABOUT.TEAM.QUESTIONS_TITLE" })}</h6>
                                        <p>{`+ 46 ${intl.formatMessage({ id: "LABEL.MILLIONS" })}`}</p>
                                    </div>
                                </Col>
                                <Col xs={6} className="d-flex align-items-center mb-4">
                                    <div className="me-3">
                                        <QrIcon ic="team" size={40} />
                                    </div>
                                    <div>
                                        <h6>{intl.formatMessage({ id: "ABOUT.TEAM.USERS_TITLE" })}</h6>
                                        <p>{`+ 1.35 ${intl.formatMessage({ id: "LABEL.MILLIONS" })}`}</p>
                                    </div>
                                </Col>
                                <Col xs={6} className="d-flex align-items-center mb-4">
                                    <div className="me-3">
                                        <QrIcon ic="hourglass" size={40} />
                                    </div>
                                    <div>
                                        <h6>{intl.formatMessage({ id: "ABOUT.TEAM.PERMANENCE_TITLE" })}</h6>
                                        <p>{`+ 27 ${intl.formatMessage({ id: "LABEL.MINUTES" })}`}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Container id="qr-about-features" fluid>
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={3}>
                                <h4>{intl.formatMessage({ id: "ABOUT.FEATURES.SUBTITLE" })}</h4>
                            </Col>
                            <Col lg={9}>
                                <Row className="align-items-stretch">
                                    <Col md={4} className="mb-3 mb-md-0">
                                        <Card>
                                            <Card.Body className="text-center">
                                                <QrIcon ic="responsive" size={48} />
                                                <Card.Title className="mt-2">{intl.formatMessage({ id: "ABOUT.FEATURES.CARD_1_TITLE" })}</Card.Title>
                                                <Card.Text>{intl.formatMessage({ id: "ABOUT.FEATURES.CARD_1_TEXT" })}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={4} className="mb-3 mb-md-0">
                                        <Card>
                                            <Card.Body className="text-center">
                                                <QrIcon ic="pill" size={48} />
                                                <Card.Title className="mt-2">{intl.formatMessage({ id: "ABOUT.FEATURES.CARD_2_TITLE" })}</Card.Title>
                                                <Card.Text>{intl.formatMessage({ id: "ABOUT.FEATURES.CARD_2_TEXT" })}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        <Card>
                                            <Card.Body className="text-center">
                                                <QrIcon ic="controller" size={48} />
                                                <Card.Title className="mt-2">{intl.formatMessage({ id: "ABOUT.FEATURES.CARD_3_TITLE" })}</Card.Title>
                                                <Card.Text>{intl.formatMessage({ id: "ABOUT.FEATURES.CARD_3_TEXT" })}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </QrSection>
        </div>
    )
}

export default About;